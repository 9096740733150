import clsx from 'clsx';
import { FC } from 'react';

interface Props {
  copyright: string;
  className?: string;
}

export const FooterCopyright: FC<Props> = ({ copyright, className }) => {
  const styles = clsx(
    'text-[1.6rem] lg:text-[1.8rem] leading-[2.4rem] lg:leading-[2.6rem]',
    className
  );

  return <span className={styles}>{copyright}</span>;
};
