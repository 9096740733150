import clsx from 'clsx';
import { FC } from 'react';
import SbEditable from 'storyblok-react';

import { SBLink } from '@/components/shared/SBLink';

import { NavLink } from '../../Footer';

interface Props {
  bottomLinks: NavLink[];
  className?: string;
}

const footerLinkStyles = clsx(
  'text-[1.6rem] lg:text-[1.8rem] leading-[2.4rem] lg:leading-[2.6rem] whitespace-nowrap',
  'hover:text-gray-100',
  'focus:text-gray-100',
  'visited:underline visited:underline-offset-4'
);

export const FooterBottomLinks: FC<Props> = ({ bottomLinks, className }) => {
  return (
    <div className={className}>
      <ul className="flex">
        {bottomLinks.map(({ link, _uid, name, ...restLink }, index) => (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          <SbEditable content={{ link, _uid, name, ...restLink }} key={_uid}>
            <li key={_uid}>
              <SBLink link={link}>
                <a className={footerLinkStyles}>
                  {name}
                  {index !== bottomLinks.length - 1 && (
                    <span className="inline-block mr-[0.1rem] sm:mx-[0.3rem]">/</span>
                  )}
                </a>
              </SBLink>
            </li>
          </SbEditable>
        ))}
      </ul>
    </div>
  );
};
