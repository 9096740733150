import { FC } from 'react';

import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';

import { StoryblokLink } from '@/lib/types/utils';

import { FooterBottomLinks } from './components/FooterBottomLinks';
import { FooterCopyright } from './components/FooterCopyright';

export interface NavLink {
  name?: string;
  link: StoryblokLink;
  _uid?: string;
  id?: string;
}

export interface FooterProps {
  copyright: string;
  columns: BlokDynamicZone[];
  bottomLinks: NavLink[];
}

export const Footer: FC<FooterProps> = ({ bottomLinks, copyright, columns }) => {
  return (
    <footer className="bg-primary text-white px-[1.6rem] py-[3.2rem] lg:pt-[4.7rem] lg:pb-[2.3rem] w-full overflow-x-hidden">
      <div className="max-w-[160rem] mx-auto grid grid-cols-1 lg:grid-cols-4 lg:gap-[3rem] xl:gap-x-[8rem]">
        <section className="grid col-span-full mb-[3rem] lg:mb-0 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-[5.3rem] lg:gap-[3rem] xl:gap-[8rem]">
          {columns.map((column) => (
            <BlokProvider key={column._uid} blok={column} />
          ))}
        </section>
        <FooterCopyright copyright={copyright} className="ld:col-span-1 mb-[0.8rem] lg:mb-0" />
        <FooterBottomLinks bottomLinks={bottomLinks} className="ld:col-span-2" />
      </div>
    </footer>
  );
};
